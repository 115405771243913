import BlogArticlePage from '../BlogArticlePage/BlogArticlePage.vue'
import NotFound from '../../NotFound/NotFound.vue'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      DEFAULT_COMMENTS_PAGE_SIZE: state => state.blog.DEFAULT_COMMENTS_PAGE_SIZE,
      article: state => state.blog.article,
      comments: state => state.blog.comments.value
    }),
    url () {
      return this.$route.params.url
    },
    hasArticle () {
      return this.article.isLoaded && this.article.value != null
    },
    blogArticlePage () {
      return this.hasArticle
        ? BlogArticlePage
        : NotFound
    }
  },
  async serverPrefetch () {
    try {
      await Promise.all([
        this.loadArticle({ url: this.url }),
        this.loadComments({
          page: 0,
          size: this.DEFAULT_COMMENTS_PAGE_SIZE,
          url: this.url
        })
      ])
    } catch (e) {
      // do nothing
    }
  },
  methods: {
    ...mapActions({
      loadArticle: 'blog/loadArticle',
      loadComments: 'blog/loadComments'
    })
  },
  async created () {
    try {
      // TODO: fix double-loading
      if (!this.article.value) {
        await Promise.all([
          this.loadArticle({ url: this.url })
        ])
      }
    } catch (e) {
      // do nothing
    }

    this.loadComments({
      page: 0,
      size: this.DEFAULT_COMMENTS_PAGE_SIZE,
      url: this.url
    })
  }
}
