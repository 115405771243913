
export default {
  props: {
    pageUrl: String,
    data: Object
  },
  computed: {
    sharedFacebook () {
      const facebookUrl = new URL('https://facebook.com/sharer/sharer.php')
      facebookUrl.searchParams.append('u', this.pageUrl)
      return facebookUrl
    },
    sharedLinkedin () {
      const postUrl = new URL(`${this.pageUrl}/`)
      postUrl.searchParams.append('title', this.data.value.title)
      postUrl.searchParams.append('summary', this.data.value.content)
      postUrl.searchParams.append('source', `${this.pageUrl}/${this.data.value.metainfo.url}`)

      const linkedinUrl = new URL('https://www.linkedin.com/shareArticle')
      linkedinUrl.searchParams.append('mini', 'true')
      linkedinUrl.searchParams.append('url', `${postUrl}`)
      return linkedinUrl
    },
    sharedTwitter () {
      const twitterUrl = new URL('https://twitter.com/intent/tweet/')
      twitterUrl.searchParams.append('text', this.data.value.title)
      twitterUrl.searchParams.append('url', `${this.pageUrl}/${this.data.value.metainfo.url}`)
      return twitterUrl
    }
  }
}
