import { mapState, mapGetters } from 'vuex'
import format from 'date-fns/format'

import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'

import ArticleSection from './ArticleSection/ArticleSection.vue'
import SharedSection from './SharedSection/SharedSection.vue'
import CommentsSection from './CommentsSection/CommentsSection.vue'

export default {
  components: {
    NavBar,
    PublicFooter,
    ArticleSection,
    SharedSection,
    CommentsSection
  },
  props: {
    url: String
  },
  metaInfo () {
    const meta = [
      ...this.data.value.seo.metatags,
      ...this.data.value.seo.ogmetatags
    ]

    const selfUrl = this.config.self.url
    const articleData = this.data.value

    const script = []

    // Bread crumb
    script.push({
      vmid: 'BreadcrumbList',
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [{
          '@type': 'ListItem',
          position: 1,
          name: 'TimeX',
          item: `${selfUrl}${this.getLanguageUrlPathPrefix()}`
        }, {
          '@type': 'ListItem',
          position: 2,
          name: 'Blog',
          item: `${selfUrl}${this.getLanguageUrlPathPrefix()}/blog`
        }, {
          '@type': 'ListItem',
          position: 3,
          name: 'Category',
          item: `${selfUrl}${this.getLanguageUrlPathPrefix()}/blog/category-${this.data.value.metainfo.categories.name}`
        }]
      }
    })

    // Article
    script.push({
      vmid: 'BlogPosting',
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `${selfUrl}${this.getLanguageUrlPathPrefix()}/${this.$route.fullPath}`
        },
        headline: articleData.title,
        image: [articleData.preview.image],
        datePublished: this.formatDate(articleData.publishDate),
        author: {
          '@type': 'Organization',
          name: 'TimeX'
        }
      }
    })

    return {
      title: articleData.title,
      meta,
      script
    }
  },
  computed: {
    ...mapState({
      data: state => state.blog.article
    }),
    ...mapGetters({
      config: 'config/config'
    }),
    blogPageUrl () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}/blog`
    }
  },
  methods: {
    formatDate (dateString) {
      return format(new Date(dateString), 'dd.MM.yyyy')
    }
  }
}
