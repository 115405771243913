import { mapState, mapActions, mapGetters } from 'vuex'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import BaseField from '@/components/lib/BaseField/BaseField.vue'
import TxTextarea from '@/components/lib/TxTextarea/TxTextarea.vue'

import format from 'date-fns/format'

export default {
  components: {
    TxButton,
    BaseField,
    TxTextarea
  },
  props: {
    pageUrl: String
  },
  data () {
    return {
      name: '',
      comment: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      DEFAULT_COMMENTS_PAGE_SIZE: state => state.blog.DEFAULT_COMMENTS_PAGE_SIZE,
      comments: state => state.blog.comments.value,
      countComments: state => state.blog.countComments,
      pageComments: state => state.blog.pageComments,
      totalPagesComments: state => state.blog.totalPagesComments,
      passport: state => state.passport?.user
    }),
    ...mapGetters({
      config: 'config/config'
    }),
    isCanLoadMoreComments () {
      return this.pageComments < (this.totalPagesComments - 1)
    },
    isCommetsLoading () {
      return this.comments.isLoading
    }
  },
  methods: {
    ...mapActions({
      postComment: 'blog/postComment',
      _loadComments: 'blog/loadComments',
      authInitiate: 'auth/initiate'
    }),
    async handleBlogHome () {
      const path = `${this.getLanguageUrlPathPrefix()}/blog`

      await this.$router.push({
        path: path
      })
    },
    formatDate (dateString) {
      return format(new Date(dateString), 'dd.MM.yyyy')
    },
    async loadMoreComments () {
      await this._loadComments({
        page: this.pageComments + 1,
        size: this.DEFAULT_COMMENTS_PAGE_SIZE,
        url: this.pageUrl,
        append: true
      })
    },
    async handleSend () {
      try {
        this.isLoading = true
        await this.postComment({
          url: this.pageUrl,
          name: this.name,
          comment: this.comment
        })
      } catch (e) {
        // TODO: handle error
      } finally {
        this.isLoading = false
        this.clearComment()
      }
    },
    clearComment () {
      this.name = ''
      this.comment = ''
    },
    async handleSignIn () {
      await this.authInitiate()
    }
  }
}
