import { mapState, mapActions, mapGetters } from 'vuex'
import format from 'date-fns/format'

export default {
  components: {
  },
  props: {
    url: String,
    data: Object,
    pageUrl: String // url
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      config: 'config/config'
    }),
    articleContent () {
      return {
        title: this.data.value.title,
        date: this.data.value.publishDate,
        tags: this.data.value.metainfo.tags,
        content: this.data.value.content,
        author: {
          avatar: this.data.value.author.avatar,
          name: this.data.value.author.name,
          position: this.data.value.author.position
        }
      }
    },
    homePageUrl () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}`
    },
    blogPageUrl () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}/blog`
    },
    categoryName () {
      return this.data.value.metainfo.categories.name
    },
    categoryUrl () {
      const result = new URL(this.blogPageUrl + '/category-' + this.categoryName)
      return result.href
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions({
    }),
    async handleBlogHome () {
      const path = `${this.getLanguageUrlPathPrefix()}/blog`

      await this.$router.push({
        path: path
      })
    },
    formatDate (dateString) {
      return format(new Date(dateString), 'dd.MM.yyyy')
    },
    async handleNavigation ({ selectCategory }) {
      const newPath = `${this.getLanguageUrlPathPrefix()}/blog/category-${this.categoryName}/`

      if (this.$route.fullPath !== newPath) {
        await this.$router.push({
          path: newPath
        })
      }
    }
  }
}
