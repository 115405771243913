import uniqid from 'uniqid'
import VueTypes from 'vue-types'

export default {
  props: {
    label: String,
    underlineText: String,
    disabled: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 2
    },
    readonly: Boolean,
    theme: VueTypes.oneOf(['normal', 'light', 'dark']).def('normal'),
    value: String,
    placeholder: String
  },
  data () {
    return {
      key: uniqid(),
      notEmpty: this.value !== '' && this.value != null
    }
  }
}
