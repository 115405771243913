import { render, staticRenderFns } from "./BlogArticlePage.htm?vue&type=template&id=b0319122&scoped=true&"
import script from "./BlogArticlePage.js?vue&type=script&lang=js&"
export * from "./BlogArticlePage.js?vue&type=script&lang=js&"
import style0 from "./BlogArticlePage.scss?vue&type=style&index=0&id=b0319122&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0319122",
  null
  
)

export default component.exports